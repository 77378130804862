@import '/src/styles-imports/shared';

$numberingWidth: 20px;

/* GAP ANALYSIS */
.gapAnalysis {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .blue {
    color: $colorPrimary1;
  }
  .cardinal {
    min-width: space('S');
    white-space: nowrap;
  }

  .classification {
    margin-top: $spaceL;

    &.forCustomReport:first-child {
      margin-top: 0;
    }

    .classificationItem {
      display: flex;
      margin-top: $spaceXs;

      .dimensionName {
        @include type('Xxs');
        font-weight: initial;
      }

      &:first-child {
        margin-top: $spaceM;
      }

      // numbering
      & > div:first-child {
        flex-basis: $numberingWidth;
        flex-shrink: 0;
        @include type('Xxs');
      }
      & > div:last-child {
        flex-basis: calc(100% - #{$numberingWidth} - 12px - #{$spaceXs}); // 12px - left margin
        max-width: calc(100% - #{$numberingWidth} - 12px - #{$spaceXs}); // 12px - left margin
        margin-left: 12px;
        margin-right: $spaceXs;

        .diagramParent {
          margin-top: $spaceM;
          @include type('Copy');

          .bottomPanel {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            column-gap: $spaceL;
            margin-top: $spaceXs;

            @include mqSmaller($bpS) {
              column-gap: $spaceS;
            }

            .indBlock {
              text-align: center;

              // score value
              & > div:last-child {
                padding-top: $spaceXxs;
                color: $colorPrimary1;
                text-align: center;
              }

              .topBlock {
                display: flex;

                .selfCircle, .othersCircle {
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  background-color: $colorPrimary1;
                }
                .selfCircle {
                  background-color: $colorYellow1;
                }

                & > span:last-child {
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

}
