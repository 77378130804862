@import '/src/styles-imports/shared';

/* STRING RESULTS */
.stringResults {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .dimension {
    margin-top: $spaceL;
  }

  .peerGroupName {
    margin: $spaceXs 0;
    font-weight: 600;
  }

  ul {
    padding: space('Xxs') 0;
    li {
      list-style-type: '»  ' !important;
      font-weight: initial;

      &::marker { 
        color: $colorPrimary1;
      }
    }
  }
}
