@import '/src/styles-imports/shared';


/* SteppedScaleResultItem */
.steppedScaleResultItem {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @include transition(background-color);
  @include transition(fill);

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .header, .headerWithNoSubDimensions {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    .left {
      display: flex;
      width: calc(50% - #{$spaceXxs});
    }
    .right {
      width: calc(50% - #{$spaceXxs});
      display: flex;
      align-items: center;

      margin-left: space('Xs');
      .points {
        min-width: 28px;
        color: $colorPrimary1;
        white-space: nowrap;

        &.big {
          min-width: 70px;
        }
      }

      .user {
        display: flex;
        align-items: center;
        .name {
          @include type('LabelL');
        }
      }

      .name {
        padding-left: 8px;
      }
    }

    @include mqSmaller($bpM) {
      position: relative;
      flex-direction: column-reverse;
      align-items: flex-start !important;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        flex-direction: row-reverse;;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 0;
        .points {
          margin-right: space('Xs');
        }
        .name {
          padding-left: 0;
        }
      }
    }
  }

  .headerWithNoSubDimensions {
    padding: space('Xs') space('M');
    @include transition(background-color);
    @include transition(fill);

    @include mqSmaller($bpM) {
      padding: space('Xxs') space('Xs') space('Xs');
    }

    &:hover {
      cursor: pointer;
      background-color: $colorPrimary4;
      @include diagramGapColor($colorPrimary4);
    }
  }

  .body {
    .subItemRow {
      display: flex;
      align-items: center;
      padding: space('Xs') space('M') space('Xs') space('Xl');
      cursor: pointer;
      @include transition(background-color);
      @include transition(fill);

      &:hover {
        background-color: $colorPrimary4;
        @include diagramGapColor($colorPrimary4);
      }
      &:focus {
        background-color: $colorPrimary3;
        outline: none;
        border: none;
        @include diagramGapColor($colorPrimary3);
      }

      .right {
        width: 100%;
        margin-left: 18px;
      }
    }

    @include mqSmaller($bpM) {
      width: 100%;
      box-sizing: border-box;
      .subItemRow {
        flex-direction: column-reverse;
        padding: space('Xxs') space('M') space('Xs') space('M');
        .right {
          width: 100%;
          margin-left: 0;
          .infoTitle {
            margin-left: 0;
          }
        }
      }
    }
  }

  .infoTitle {
    align-items: flex-start;
    margin-left: 0;

    span {
      white-space: pre-wrap;
    }

    svg {
      margin-top: 3px;
    }

    @include mqSmaller($bpS) {
      margin-left: 0;
    }
  }

  .collapsibleHeader {
    padding: space('Xs') space('M') !important;
    @include transition(background-color);
    @include transition(fill);

    &:hover {
      background-color: $colorPrimary4;
      @include diagramGapColor($colorPrimary4);
    }

    &.active {
      background-color: $colorPrimary3;
      @include diagramGapColor($colorPrimary3); 
    }

    @include mqSmaller($bpM) {
      padding: space('Xs') space('Xs') !important;

      div:last-child {
        svg {
          position: absolute;
          right: space('Xs');
          top: 19px;
        }
      }
    }
  }

}
