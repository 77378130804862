@import '/src/styles-imports/shared';

/* IMGCIRCLE */
.imgCircle {
  position: relative;
  box-sizing: border-box;
  width: 50px;
  height: 50px;

  color: $colorPrimary1;
  @include type('LabelL');
  line-height: 50px !important;
  text-align: center;

  border-radius: 50%;
  border: 1px solid $colorPrimary4;
  background-color: $colorPrimary4;

  overflow: hidden;

  // transitions
  @include transition(border-color, background-color);

  @keyframes pulse {
    0% {
      background-color: white;
    }
    100% {
      background-color: $colorPrimary4;
    }
  }



  // MODIFIERS
  &.loading {
    @include animationLong(pulse);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }


  &.sizeXs {
    //24px
    width: 24px;
    height: 24px;
    line-height: 24px !important;
    font-size: 10px !important;
  }
  &.sizeSs {
    // 30px
    width: 30px;
    height: 30px;
    line-height: 30px !important;
    font-size: 10px !important;
  }

  &.sizeS {
    // 36px
    width: 36px;
    height: 36px;
    line-height: 36px !important;
    font-size: 12px !important;
  }
  &.sizeHeader {
    // 38px
    width: 38px;
    height: 38px;
    line-height: 38px !important;
    font-size: 12px !important;
  }
  &.sizeM {
    width: 75px;
    height: 75px;
    line-height: 75px !important;
    font-size: 16px !important;
  }
  &.sizeL {
    // 80px
    width: 80px;
    height: 80px;
    line-height: 80px !important;
    font-size: 20px !important;
  }

  &.hasHover:hover {
    cursor: pointer;
    border-color: $colorPrimary1;
  }

  &.darker {
    background-color: $colorPrimary3;
    &.loading {
      @include animationLong(pulse);
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }

  &.fontDarker {
    color: $colorGrey1;
  }

  &.noBorder {
    border: 0;
  }
  &.noBackground {
    background-color: transparent;
  }



  // CHILDREN
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    z-index: 1;

    @include transition(opacity);

    &.show {
      opacity: 1;
    }

    img {
      margin-bottom: 1px;
      width: 100%;
      height: 100%;
      border-radius: 50%; // circle
      object-fit: cover;
    }
  }

}

