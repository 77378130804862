@import '/src/styles-imports/shared';

/* ASSESSMENTS */
.assessments {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .loading {
    margin-top: space('Xs');
  }

  .noData {
    margin-top: space('S');
    text-align: center;
  }

  .noAssessments {
    margin-top: $spaceXl;
    text-align: center;

    @include mqSmaller($bpS) {
      margin-top: $spaceL;
    }

   & > svg {
     margin-top: $spaceS;
   }
  }

  .run {
    padding: 0 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: space('S');
    gap: space('S');

    .description {
      text-align: initial;
    }
    svg g {
      opacity: 1;
    }
  }

  .gridContainer {
    @include basicGridNoMargins;

    .full {
      grid-column: start / end;
    }

    .left {
      grid-column: start / 9;
    }
    .right {
      grid-column: 9 / end;

      &.extraTopMargin {
        margin-top: 60px;
      }
    }
    .left, .right {
      & > :not(:first-child) {
        margin-top: $spaceXs;
      }
    }

    @include mqSmaller($bpM) {
      .left, .right {
        grid-column: start / end;
      }
      .right {
        margin-top: 0;
        &.extraTopMargin {
          margin-top: 0;
        }

        & > div {
          margin-top: $spaceXs;
        }
      }
    }
  }
}
