@import '/src/styles-imports/shared';

.attachmentsModalAdd {
  h5 {
    @include type('Xxs');
    margin-bottom: 8px;
  }

  h6 {
    @include type('LabelL');
    margin-bottom: 8px;
  }

  p {
    @include type('Copy');
    margin-bottom: 24px;
  }

  form {
    > div {
      margin-bottom: 16px;
    }
  }
}

.linkAddButtonWrapper {
  text-align: right;
}

.linkSaved {
  margin-bottom: 24px;
}
.errorMessage {
  margin-top: $spaceXs;
  margin-bottom: $spaceXs;
  @include type('Copy');
  color: $colorDanger1;
}

.documentAddButtonWrapper {
  text-align: right;

  > button:first-child {
    margin-right: 16px;
  }
}
