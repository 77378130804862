@import '/src/styles-imports/shared';

.profile {
  .imageBlock {
    display: flex;
    .left {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: $colorPrimary4;
      line-height: 75px;
      text-align: center;
      &.halfVisible {
        opacity: 50%;
      }
      img {
        width: 75px;
        height: 74px;
        border-radius: 50%;
      }
      .userName {
        @include type('Xxs');
        color: $colorPrimary1;
        @include ellipsis();
      }
    }
    .right {
      margin-left: space('Xs');
      word-break: break-all;
      .name {
        @include type('Xxs');
        color: $colorGrey1;
      }
      .position, .company, .deactivated, .roles {
        @include type('Copy');
        margin-top: space('Xxxs');
      }
      .roles {
        color: $colorPrimary1;
        cursor: pointer;
        @include transition(color);
        &:hover {
          color: $colorPrimary2;
        }
      }
      .deactivated {
        color: $colorPrimary1;
      }
    }
  }

  .header {
    @include type('LabelL');
    padding: space('S') space('Xs');
  }

  .listItem {
    padding: space('Xs') space('Xxs');

    //margins with negative values to decrease the margins of CollapsibleNext content
    margin-right: -(space('Xs'));
    margin-left: -(space('Xxs'));

    border-bottom: 1px solid $colorGrey4;

    &:first-child {
      border-top: 1px solid $colorGrey4;
    }

    .label {
      @include type('Hint');
      color: $colorGrey2;
    }
    .value {
      @include type('Copy');
      margin-top: space('Xxs');
      user-select: text;
    }
    .manager {
      display: flex;
      align-items: center;
      margin-top: space('Xxs');

      & > span {
        @include type('Copy');
        padding-left: $spaceXxs;
      }
    }
  }
}
